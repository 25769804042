/* .formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  
  .btnsHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 60%;
  }
  
  .btnPrev {
    background-color: #ffdddd;
    cursor: pointer;
    border: none;
    border-radius: 0.75rem;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    padding: 1rem 2rem;
    margin-bottom: 3rem;
  }
  .btnNext {
    cursor: pointer;
    color: #ffffff;
    border: none;
    border-radius: 0.75rem;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
  
    margin-bottom: 3rem;
  }
  .stepper {
    margin-top: 2rem;
    width: 80%;
  }
  
  
  
  @media (min-width: 992px) {
    .stepper {
      display: none;
    }
  }
  .btnExporterDervis {
    background-color: #f0f3f8;
    cursor: pointer;
    border: 1px solid #8caed6;
    border-radius: 0.625rem;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    padding: 1rem 2rem;
    margin-bottom: 3rem;
    color: #8caed6;
  }
  .btnEnvoyerDevis {
    background-color: #91b1d8;
    cursor: pointer;
    border: none;
    border-radius: 0.625rem;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    padding: 1rem 2rem;
    margin-bottom: 3rem;
    color: #fff;
  }
  .btnDevis {
    background-color: #f0f3f8;
    border-radius: 0.6875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
  }
  .warning{
    background-color: #fffbe6 !important;
    border: #ffe58f 2px solid;
  }
   */
   /* .btnsHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 60%;
  } */
  .colBtn {
    display: flex;
    align-items: center;
    
    justify-content: center;
    margin-block: 2rem;
  }
  /* .buttons {

    background-color: #EAFAF3 !important;
    width: 11rem;
    height: 2.5rem;
    color: #26CB87 !important;
    border-radius: 10px!important;
    margin-top: 2rem !important;
    border: 1px solid #26CB87 !important;
  background: #FFF;
    font-size: 0.8rem !important;
    font-family: "Montserrat regular", sans-serif !important;
    font-weight: 600 !important;
    margin-bottom: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
  
  } */