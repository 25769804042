.header {
  /* background-image: url(https://mutuelle-direct.cloud.geoprod.com/images/bg-header.png); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  justify-content: space-between;
  position: sticky;
  top: 0;
  min-height: 84px;
  z-index: 1000;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e1;
}

.headerCol {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-inline: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.organismTitle {
  font-weight: 500;
  font-size: 1.75rem;
  font-family: "Montserrat", sans-serif !important;
}

.title {
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Montserrat", sans-serif !important;
  font-size: 2rem;
}

.outlet {
  position: relative;
}

.footer {
  margin-top: 4rem;
  background-color: #f7f7f7;
  border-top: 1px solid #e0e0e1;
  padding: 20px;
  align-items: center;
  /* width: 100%; */
  /* margin-inline: 0; */
}

p {
  margin: 0;
}

.footerCol {
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* padding-bottom: 5rem; */
  font-size: 0.5rem;
}

.solution {
  padding-bottom: 0.5rem;
}

.garantie {
  padding-bottom: 0.5rem;
}