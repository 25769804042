.memberRow {
  margin-top: 2rem;
  background-color: white;
  border-radius: 20px;
  padding: 1rem 0rem;
}

.newChildButton {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.6rem;
}

.title {
  color: #2d2d2d;
  font-family: "Montserrat semibold" sans-serif;
  font-size: 1.5rem;

  margin-bottom: 1rem;
}

.conjointButton {
  margin-right: 1rem;
  background-color: #c4cee6;
  border: none;
  border-radius: 0.5rem;
  color: #254484;
  font-family: "Montserrat regular " sans-serif;
}

.addChildButton {
  background-color: #d2efeb;
  border: none;
  border-radius: 0.5rem;
  color: #585858;
  font-family: "Montserrat regular " sans-serif;
}