.contain {
  padding-inline: 2rem;
  padding-block: 1rem;
}

.rowdevis {
  background-color: #f0f0f0;
  height: 4rem;
  padding: 1.25rem;
}

.devis {
  color: red;
  text-align: center;
}
.rowemail {
  background-color: white;
  padding-block: 2rem;
}
.rowbtn {
  background-color: white;
}
.btn {
  display: flex;
  justify-content: center;

  padding-bottom: 2rem;
}
.rowtext {
  background-color: white;
}
.text {
  padding-bottom: 2rem;
  text-align: justify;
}
.boutonenvoyer {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
  padding-right: 0.05rem;
}
.header {
  /* background-color: #13274F; */
  height: 4rem;
  padding: 1.25rem;

  height: 5.5rem;
  padding: 1rem;
  position: relative;
}
.title {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.head {
  height: 4rem;
  width: 8rem;
  position: absolute;
}
