.pack {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 1rem;
}

.card {
  padding-block: 1rem;
  padding-inline: 1rem;
  cursor: pointer;
  border-radius: 12px;

  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.nameProposition {
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
  margin-block: 1rem;
}

.tarif {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.text {
  margin-left: 0.4rem;
  font-size: 20px;
  font-weight: 600;
}
