:root {
  font-family: "Inter", sans-serif;
}

* {
  font-family: "Inter", sans-serif;
}

.montserrat-ff {
  font-family: "Montserrat", sans-serif !important;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  opacity: 0.7;

}

/* 
:where(.css-dev-only-do-not-override-xu9wm8).ant-picker
  .ant-picker-input
  > input {
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  font-family: "Montserrat" sans-serif;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-picker-focused.ant-picker {
  border: 1px solid #d9d9d9;
  box-shadow: none;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-input-number {
  font-size: 1rem;
  font-weight: 300;
  color: #212529;
  font-family: "Montserrat" sans-serif !important ;
}
.ant-form-vertical .ant-form-item-label >label, :where(.css-dev-only-do-not-override-hhykwn).ant-col-24.ant-form-item-label >label, :where(.css-dev-only-do-not-override-hhykwn).ant-col-xl-24.ant-form-item-label >label {

  color: #444646;
  font-size: 15px;
  font-weight: 500;

} */