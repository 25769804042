.titre {
    margin-left: 2rem;
    font-size: 16px;
    padding-top: 0.4rem;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
  }
  
  .label {
    color: #757474;
    font-size: 1rem !important;
    margin-bottom: 0.5rem;
  }
  .iconRemove {
    top: 0.6rem;
    right: 0.5rem;
    font-size: 1.25rem;
    color: #3a3a3a;
  }
  .containerCard {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    border-radius: 0.6875rem;
    border: 0.5px solid #c7d2dc;
    background: white;
    margin-block: 1rem;
    width: 100%;
    box-shadow: 0px 2px 6px 0px rgba(30, 30, 30, 0.25);
  }
  