.section {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.propositionText {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;

}

.tarificationCol {
  text-align: center;
}

.tarificationText {
  font-family: "Montserrat medium" sans-serif;
  color: #2d2d2d;
  font-size: 1rem;
  font-weight: 500;
}

.box {
  height: fit-content;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 70rem;
}

.switchComponent {
  display: flex;
  flex-direction: row;
}

.mensuelleChecked {
  font-size: 1.5rem;
  font-weight: 500;
}

.mensuelle {
  color: #a4a4a4;
  font-size: 1.5rem;
  font-weight: 500;
}

.annuelle {
  font-size: 1.5rem;
  font-weight: 500;
}

.annuelleChecked {
  color: #a4a4a4;
  font-size: 1.5rem;
  font-weight: 500;
}

.garantieChoix {
  color: #2d2d2d;
  font-size: 1rem;
  font-weight: 500;
}

.container {
  display: block;
  position: relative;
  margin-left: 17rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.BoxPropositionChecked {
  border: 1px solid lightgray;
  width: 23rem;
  height: 11rem;
  border-radius: 0.5rem;
  margin-inline: 1rem;
  cursor: pointer;
  background-color: rgba(236, 241, 249, 1);
  margin-bottom: 1rem;
  position: relative;
}

.BoxProposition {
  border: 1px solid lightgray;
  width: 23rem;
  height: 11rem;
  border-radius: 0.5rem;
  margin-inline: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  position: relative;
}

.proposition {
  color: #254484;
  font-weight: 600;
  font-size: 1.3rem;
  text-align: left;
  margin: 0 !important;
  padding-top: 2rem;
}

.propositionTotal {
  color: #254484;
  font-weight: 600;
  font-size: 2.5rem;
  text-align: left;
}

.propositionType {
  color: #254484;
  font-weight: 450;
  font-size: 1.5rem;
  text-align: center;
  margin-left: 1rem;
}

.buttons {
  background-color: #eafaf3 !important;
  width: 11rem;
  height: 2.5rem;
  color: #26cb87 !important;
  border-radius: 10px !important;
  margin-top: 2rem !important;
  border: 1px solid #26cb87 !important;
  background: #fff;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  margin-bottom: 2rem !important;
  /* margin-left: 0.5rem !important; */
}

.retourButton {
  background-color: rgba(147, 5, 5, 1) !important;
  color: white !important;
  width: 10rem !important;
  border: none !important;
  margin-bottom: 3rem !important;
  border-radius: 1rem !important;
  margin-top: 5rem !important;
}

.confirmButton {
  background-color: #254484 !important;
  color: white !important;
  width: 10rem !important;
  border: none !important;
  margin-bottom: 3rem !important;
  border-radius: 1rem !important;
  margin-top: 5rem !important;
}

.RetourCol {
  display: flex;
  justify-content: flex-end;
}

.ButtonRadioChecked {
  margin-top: 0.4rem;
  position: absolute;
  right: 0.5rem;
}

.loaderContainer {
  display: flex;
  flex-direction: column;

  text-align: center;
}

.propositionBoxs {
  display: flex;
  justify-content: center;
}