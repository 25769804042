.titleContainer {
    background-color: #F2F0FF;
    border-radius: 13px;
    padding: 0.6rem 1rem 0.6rem 1rem ;
    width: 22rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 2rem;
  }
  .title{
    margin: 0;
    font-weight: 600;
    font-size: 20px;
  
  }
  
  .assureIcon{
    margin-inline: 0.5rem;
  }