.ModalSuccess {
  padding: 2rem;
}
.colIcon {
  margin-block: 1.5rem;
}

.souscriptionTerminée {
  color: #0b0b0b;
  font-size: 1rem;
  font-weight: 500;
}
.felicitation {
  color: #0b0b0b;
  font-size: 1rem;
  font-weight: 700;
}

.modalRow {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.souscription {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  color: #0b0b0b;
  font-size: 0.8rem;
  font-weight: 400;
}
