.container {
  margin: 2rem auto;
  border: 1px solid #e0e0e1;
  border-radius: 13px;
  /* width: 30%; */
  box-shadow: 0px 2px 15px 0px #0000001f;
  background-color: #fff;
  padding-bottom: 2rem;
  width: 90%;
  min-height:78vh
}
.steps{
  font-size: 1.2rem;
   font-weight: 500
}

.loaderContainer{
    display: flex;
    flex-direction: column;
    
    text-align: center;
  }